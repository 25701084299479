import { lazy } from "react";

const PagesRoutes = [
  // Authentication
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/auth/login")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../../view/pages/auth/forgot-password")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/resend-forgot-password",
    component: lazy(() =>
      import("../../view/pages/auth/resend-email/forgot-password")
    ),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/reset-password",
    component: lazy(() => import("../../view/pages/auth/reset-password")),
    layout: "FullLayout",
    auth: false,
  },

  // Main Pages
  {
    path: "/dashboard",
    exact: true,
    component: lazy(() => import("../../view/pages/dashboard")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
  },
  {
    path: "/chat",
    component: lazy(() => import("../../view/pages/chat/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["room-index", "room-show", ""],
  },
  {
    path: "/chat-message",
    component: lazy(() => import("../../view/pages/chat/chat-message")),
    layout: "VerticalLayout",
    auth: true,
    access: ["room-index", "room-show"],
  },
  {
    path: "/archive",
    component: lazy(() => import("../../view/pages/archive/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["room-index", "room-show"],
  },
  {
    path: "/archive-message",
    component: lazy(() => import("../../view/pages/archive/chat-message")),
    layout: "VerticalLayout",
    auth: true,
    access: ["room-index", "room-show"],
  },
  // Other
  {
    path: "/system/data",
    component: lazy(() => import("../../view/pages/system/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["system-index", "system-show"],
  },
  {
    path: "/system/form",
    component: lazy(() => import("../../view/pages/system/form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["system-show", "system-update"],
  },
  {
    path: "/user/data",
    component: lazy(() => import("../../view/pages/user/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["user-index", "user-show"],
  },
  {
    path: "/user/form",
    component: lazy(() => import("../../view/pages/user/form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["user-store", "user-update"],
  },
  {
    path: "/role/data",
    component: lazy(() => import("../../view/pages/role/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["role-index", "role-show"],
  },
  {
    path: "/role/form",
    component: lazy(() => import("../../view/pages/role/form")),
    layout: "VerticalLayout",
    auth: true,
    access: ["role-store", "role-update"],
  },
  {
    path: "/role/permission",
    component: lazy(() => import("../../view/pages/role/permission")),
    layout: "VerticalLayout",
    auth: true,
    access: ["permission-index", "permissions-show"],
  },
  {
    path: "/permission/data",
    component: lazy(() => import("../../view/pages/permission/data")),
    layout: "VerticalLayout",
    auth: true,
    access: ["permission-index", "permissions-show"],
  },
  {
    path: "/permission/form",
    component: lazy(() => import("../../view/pages/permission/form")),
    layout: "VerticalLayout",
    auth: true,
    access: [
      "permission-index",
      "permissions-show",
      "sync-permission-index",
      "sync-permission-update",
    ],
  },
  {
    path: "/profile/my-profile",
    component: lazy(() => import("../../view/pages/profile/my-profile")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
  },
  {
    path: "/profile/change-profile",
    component: lazy(() => import("../../view/pages/profile/change-profile")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
  },
  {
    path: "/profile/change-password",
    component: lazy(() => import("../../view/pages/profile/change-password")),
    layout: "VerticalLayout",
    auth: true,
    access: null,
  },
];

export default PagesRoutes;
