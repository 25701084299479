export const api_base_url = process.env.REACT_APP_BASE_URL + "/api/v1/";
export const api_websocket_url = process.env.REACT_APP_WEBSOCKET_URL + "/ws/";
export const api_dashboard =
  process.env.REACT_APP_BASE_URL + "/api/v1/dashboard/";

export const API_URL = {
  // Authentication
  login: api_base_url + "auth/login",
  forgotPassword: api_base_url + "auth/send-password-email",

  // Dashboard
  stats: api_dashboard + "stats",
  room: api_dashboard + "room",
  archiveRoom: api_dashboard + "inactive-room",
  chat: api_dashboard + "chat",
  user: api_dashboard + "user",
  role: api_dashboard + "role",
  sync_permission: api_dashboard + "sync-permission",
  permission: api_dashboard + "permission",
  system: api_dashboard + "system",

  // Profile
  profile: api_dashboard + "profile",
};

export const WEBSOCKET_API_URL = {
  room: api_websocket_url + "room",
};
