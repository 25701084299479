import React, { createContext, useContext, useState, useEffect } from "react";

import { notification } from "antd";

import notifRingtone from "./assets/audio/ringtone.wav";
import { InfoCircle } from "react-iconly";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { WEBSOCKET_API_URL } from "./api/config";

const WebSocketNotifContext = createContext();

export const WebSocketNotifProvider = ({ children }) => {
  const history = useHistory();

  const [webSocket, setWebSocket] = useState([]);

  const websocketNotification = (token) => {
    var socket = new WebSocket(WEBSOCKET_API_URL.room + "?token=" + token);

    socket.onmessage = function (event) {
      setWebSocket(event.data);

      const json = JSON.parse(event.data);

      const storage = localStorage.getItem("_user_session");
      if (storage) {
        const username = JSON.parse(storage).username;

        if (json?.data?.[0]?.last_message_from !== username) {
          openNotification(json?.data?.[0]);
        }
      }
    };

    socket.onclose = function (e) {
      checkSession();
    };
    window.onbeforeunload = (event) => {
      socket.close();
    };
  };

  const handleNotificationClick = (url) => {
    window.location.href = url;
  };

  notification.config({
    maxCount: 3,
  });

  const openNotification = (data) => {
    const audio = new Audio(notifRingtone);
    audio.volume = 1;
    audio.play();

    notification.open({
      message: "Pesan Masuk dari " + data.last_message_from,
      description: data.last_message,
      placement: "topRight",
      duration: 60,
      icon: <InfoCircle size={24} className={`hp-text-color-primary-1`} />,
      className: "hp-cursor-pointer",
      onClick: () => handleNotificationClick("/chat?q=" + data.uuid),
    });
  };

  const checkSession = () => {
    const storage = localStorage.getItem("_user_session");
    if (storage) {
      const token = JSON.parse(storage).accessToken;
      websocketNotification(token);
    }
  };
  // useEffect
  useEffect(() => {
    checkSession();
  }, []);

  return (
    <WebSocketNotifContext.Provider value={webSocket}>
      {children}
    </WebSocketNotifContext.Provider>
  );
};

export const useWebSocketNotif = () => {
  return useContext(WebSocketNotifContext);
};
