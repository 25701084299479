import React, { useEffect, useState } from "react";

import { Row, Col, Button, Card } from "antd";

import PageTitle from "../../../layout/components/content/page-title";
import themeConfig from "../../../configs/themeConfig";
import { Link } from "react-router-dom";
import { RiArrowLeftLine, RiHeadphoneLine, RiUserLine } from "react-icons/ri";
import BottomMenu from "../../../layout/components/bottom-menu";
import { People, Ticket, TicketStar, TimeCircle } from "react-iconly";
import { get } from "../../../utils/service";
import { API_URL } from "../../../api/config";

// Custom Icons
import IconSystem from "../../../assets/images/pages/dashboard/icon-system.svg";
import IconUsers from "../../../assets/images/pages/dashboard/icon-users.svg";
import IconPermission from "../../../assets/images/pages/dashboard/icon-permission.svg";

export default function Pages() {
  const [dataSource, setDataSource] = useState([]);

  const getData = async () => {
    const res = await get(API_URL.stats);
    if (res.status === 200) {
      setDataSource(res.data.data);
    }
  };

  useEffect(() => {
    getData();
    document.title = "Dashboard - " + themeConfig.appname;
  }, []);

  // Device Size Handling
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <>
      <PageTitle pageTitle="Dashboard" />

      <div className="hp-content-main">
        <Row gutter={[16, 16]} className="hp-mb-32">
          <Col span={24} lg={12}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-danger-4 hp-bg-dark-danger hp-text-color-danger-1 icon">
                  <TimeCircle size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Total Antrian
                  </p>
                  <h4 className="hp-mb-0">{dataSource?.queue}</h4>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={24} lg={12}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-orange-4 hp-bg-dark-orange hp-text-color-orange-1 icon">
                  <RiHeadphoneLine size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Total CS/Support
                  </p>
                  <h4 className="hp-mb-0">{dataSource?.admin}</h4>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={24} lg={12}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-success-4 hp-bg-dark-success hp-text-color-success-1 icon">
                  <Ticket size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Tiket Hari Ini
                  </p>
                  <h4 className="hp-mb-0">{dataSource?.today_room}</h4>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={24} lg={12}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-info-4 hp-bg-dark-info hp-text-color-info-1 icon">
                  <TicketStar size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Total Tiket
                  </p>
                  <h4 className="hp-mb-0">{dataSource?.room}</h4>
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        {dimensions.width < 1199 && (
          <Row gutter={[16, 16]}>
            <Col span={8} className="hp-text-center">
              <Link to="/system/data">
                <div className="hp-d-flex hp-d-flex-justify-center">
                  <div
                    className="hp-p-16 hp-mx-auto hp-bg-color-black-0 hp-bg-color-dark-100 hp-border-radius"
                    style={{ width: "100px", height: "100px" }}
                  >
                    <img src={IconSystem} alt="Icon System" />
                  </div>
                </div>
                <div className="hp-mt-16 hp-text-color-black-80 hp-text-color-dark-30">
                  Manajemen Sistem
                </div>
              </Link>
            </Col>
            <Col span={8} className="hp-text-center">
              <Link to="/user/data">
                <div className="hp-d-flex hp-d-flex-justify-center">
                  <div
                    className="hp-p-16 hp-mx-auto hp-bg-color-black-0 hp-bg-color-dark-100 hp-border-radius"
                    style={{ width: "100px", height: "100px" }}
                  >
                    <img src={IconUsers} alt="Icon Users" />
                  </div>
                </div>
                <div className="hp-mt-16 hp-text-color-black-80 hp-text-color-dark-30">
                  Manajemen User
                </div>
              </Link>
            </Col>
            <Col span={8} className="hp-text-center">
              <Link to="/role/data">
                <div className="hp-d-flex hp-d-flex-justify-center">
                  <div
                    className="hp-p-16 hp-mx-auto hp-bg-color-black-0 hp-bg-color-dark-100 hp-border-radius"
                    style={{ width: "100px", height: "100px" }}
                  >
                    <img src={IconPermission} alt="Icon Permissions" />
                  </div>
                </div>
                <div className="hp-mt-16 hp-text-color-black-80 hp-text-color-dark-30">
                  Role & Permissions
                </div>
              </Link>
            </Col>
          </Row>
        )}
      </div>

      <BottomMenu active="home" />
    </>
  );
}
